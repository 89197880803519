
// Libraries
import * as React from 'react'

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCogs} from '@fortawesome/pro-solid-svg-icons'
import Button from '../../button'
import ReactTooltip from 'react-tooltip'

class AdminContentManagementTableHeader extends React.Component {

	render() {
		const {titleIcon, titleText, infoText, handleConfirmBuild} = this.props

		return <div className="admin-table__header">
			<div className="column column--title">
				<h1><FontAwesomeIcon icon={titleIcon} /> {titleText}</h1>
				{(infoText && infoText !== '') && <p>{infoText}</p>}
			</div>
			<div className="column column--search">
				<Button onClick={handleConfirmBuild} iconOnly data-tip data-for="tooltip-build">
					<FontAwesomeIcon icon={faCogs} title="Build icon" />
					<span className="sr-only">Trigger Build</span>
					<ReactTooltip id="tooltip-build" place="top" backgroundColor="#005EB8" textColor="#fff">
						<span>Trigger Build</span>
					</ReactTooltip>
				</Button>
			</div>
		</div>
	}
}

export default AdminContentManagementTableHeader
