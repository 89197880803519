
// Libraries
import React from 'react'

// Components
import Button from './button'
import InputFactory from './inputFactory'
import AdminViewRow from './admin/adminViewRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import Classnames from 'classnames'

export default class InputGroupVenuePartners extends React.Component {
	render() {
		const {values, inputChange, inputAdd, inputRemove} = this.props

		let classes = Classnames([
			'input-wrap',
			'input-wrap--content-management-input-group',
			'input-wrap--venue-partners',
		])

		return <div className={classes}>
			<div className='groups-wrap'>
				{values?.length
					? values.map((value, key) => {
						return <div className="admin-form__field-group group-wrap">
							<h2>Venue {key + 1}</h2>
							<AdminViewRow
								label="Name*"
								value={<InputFactory
									type="text"
									name={`content[venues][${key}][name]`}
									value={value?.name}
									onChange={inputChange}
								/>}
							/>
							<AdminViewRow
								label={<>Logo*<br/><small>Size: 350px x 210px</small></>}
								value={<InputFactory
									type="file"
									name={`content[venues][${key}][image_logo]`}
									value={value?.event}
									initialFilePreview={value?.image_logo || null}
									onChange={inputChange}
								/>}
							/>
							<Button type='button' iconOnly danger onClick={() => inputRemove('venues', key)}>
								<FontAwesomeIcon icon={faTrashAlt} /><span className='sr-only'> Remove</span>
							</Button>
						</div>
					})
					: <p>Add a venue to start ...</p>
				}
			</div>
			<div className='buttons-wrap'>
				<Button type='button' onClick={() => inputAdd('venues')}>
					<FontAwesomeIcon icon={faPlus} /> Add venue
				</Button>
			</div>
		</div>
	}
}