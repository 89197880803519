
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons'

// Context
import NotificationContext from '../../../contexts/notification'

class AdminContentManagementTableRow extends React.Component {

	static contextType = NotificationContext

	render() {
		const {item, handleOpenEdit} = this.props
		let classNames = Classnames([
			'admin-table__row',
		])

		return <tr className={classNames}>
			<td className="admin-table__cell--name">{item.name}</td>
			<td className="admin-table__cell--actions">
				<button onClick={() => handleOpenEdit(item.id)}>
					<FontAwesomeIcon icon={faArrowRight} title="Edit icon" /><span className="sr-only"> Edit</span>
				</button>
			</td>
		</tr>
	}
}

export default AdminContentManagementTableRow
