
// Libraries
import React from 'react'

// Components
import Button from './button'
import InputFactory from './inputFactory'
import AdminViewRow from './admin/adminViewRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import Classnames from 'classnames'

export default class InputGroupTeamProfiles extends React.Component {
	render() {
		const {values, inputChange, inputAdd, inputRemove} = this.props

		let classes = Classnames([
			'input-wrap',
			'input-wrap--content-management-input-group',
			'input-wrap--team-profiles',
		])

		return <div className={classes}>
			<div className='groups-wrap'>
				{values?.length
					? values.map((value, key) => {
						return <div className="admin-form__field-group group-wrap">
							<h2>Profile {key + 1}</h2>
							<AdminViewRow
								label="Name*"
								value={<InputFactory
									type="text"
									name={`content[profiles][${key}][name]`}
									value={value?.name}
									onChange={inputChange}
								/>}
							/>
							<AdminViewRow
								label="Position*"
								value={<InputFactory
									type="text"
									name={`content[profiles][${key}][position]`}
									value={value?.position}
									onChange={inputChange}
								/>}
							/>
							<AdminViewRow
								label={<>Photo*<br/><small>Size: 640px x 640px</small></>}
								value={<InputFactory
									type="file"
									name={`content[profiles][${key}][image_photo]`}
									value={value?.event}
									initialFilePreview={value?.image_photo || null}
									onChange={inputChange}
								/>}
							/>
							<AdminViewRow
								label="Contacts"
								value={<InputFactory
									type="text"
									name={`content[profiles][${key}][contacts]`}
									value={value?.contacts}
									onChange={inputChange}
								/>}
							/>
							<AdminViewRow
								label="Favourite Artist*"
								value={<InputFactory
									type="text"
									name={`content[profiles][${key}][favourite_artist]`}
									value={value?.favourite_artist}
									onChange={inputChange}
								/>}
							/>
							<AdminViewRow
								label="Best Gig*"
								value={<InputFactory
									type="text"
									name={`content[profiles][${key}][best_gig]`}
									value={value?.best_gig}
									onChange={inputChange}
								/>}
							/>
							<AdminViewRow
								label="Profile Text*"
								value={<InputFactory
									type="textarea"
									name={`content[profiles][${key}][profile_text]`}
									value={value?.profile_text}
									onChange={inputChange}
									rows="8"
								/>}
							/>
							<Button type='button' iconOnly danger onClick={() => inputRemove('profiles', key)}>
								<FontAwesomeIcon icon={faTrashAlt} /><span className='sr-only'> Remove</span>
							</Button>
						</div>
					})
					: <p>Add a profile to start ...</p>
				}
			</div>
			<div className='buttons-wrap'>
				<Button type='button' onClick={() => inputAdd('profiles')}>
					<FontAwesomeIcon icon={faPlus} /> Add profile
				</Button>
			</div>
		</div>
	}
}