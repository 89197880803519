
// Services
import ApiService from './api'

// Initialise new instance of the API service
const apiService = new ApiService()

export default class AppService {
	async triggerBuild(queryParams = {}) {
		return await apiService.get(`app/trigger-gatsby-build`, {}, queryParams)
	}
}
