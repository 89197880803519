
// Libraries
import React from 'react'

// Components
import Button from './button'
import InputFactory from './inputFactory'
import AdminViewRow from './admin/adminViewRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import Classnames from 'classnames'

export default class InputGroupTestimonialQuotes extends React.Component {
	render() {
		const {values, inputChange, inputAdd, inputRemove} = this.props

		let classes = Classnames([
			'input-wrap',
			'input-wrap--content-management-input-group',
			'input-wrap--testimonial-quotes',
		])

		return <div className={classes}>
			<div className='groups-wrap'>
				{values?.length
					? values.map((value, key) => {
						return <div className="admin-form__field-group group-wrap">
							<h2>Quote {key + 1}</h2>
							<AdminViewRow
								label="Name*"
								value={<InputFactory
									type="text"
									name={`content[quotes][${key}][name]`}
									value={value?.name}
									onChange={inputChange}
								/>}
							/>
							<AdminViewRow
								label="Event*"
								value={<InputFactory
									type="text"
									name={`content[quotes][${key}][event]`}
									value={value?.event}
									onChange={inputChange}
								/>}
							/>
							<AdminViewRow
								label="Quote*"
								value={<InputFactory
									type="textarea"
									name={`content[quotes][${key}][quote]`}
									value={value?.quote}
									onChange={inputChange}
									rows="8"
								/>}
							/>
							<AdminViewRow
								label="Link"
								value={<InputFactory
									type="text"
									name={`content[quotes][${key}][link]`}
									value={value?.link}
									onChange={inputChange}
								/>}
							/>
							<Button type='button' iconOnly danger onClick={() => inputRemove('quotes', key)}>
								<FontAwesomeIcon icon={faTrashAlt} /><span className='sr-only'> Remove</span>
							</Button>
						</div>
					})
					: <p>Add a quote to start ...</p>
				}
			</div>
			<div className='buttons-wrap'>
				<Button type='button' onClick={() => inputAdd('quotes')}>
					<FontAwesomeIcon icon={faPlus} /> Add quote
				</Button>
			</div>
		</div>
	}
}