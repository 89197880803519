
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

// Components
import AdminContentManagementTableRow from './adminContentManagementTableRow'

class AdminContentManagementTable extends React.Component {
	render() {
		const {items, emptyMessage, handleOpenEdit, updateStateItems} = this.props

		let classNames = Classnames([
			'admin-table admin-table--blog wrap--scrollx',
		])

		return (
			<div className={classNames}>
				<table className="admin-table__table">
					<thead>
					<tr>
						<th>Page</th>
						<th className="actions"><span className="sr-only">Actions</span></th>
					</tr>
					</thead>
					<tbody>
					{
						items?.length
							? items.map((item) => <AdminContentManagementTableRow
								key={item.id}
								item={item}
								handleOpenEdit={handleOpenEdit}
								updateStateItems={updateStateItems} />)
							: <tr><td colSpan={100} align="center">{emptyMessage}</td></tr>
					}
					</tbody>
				</table>
			</div>
		)
	}
}

export default AdminContentManagementTable
