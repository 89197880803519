
// Services
import ApiService from './api'

// Initialise new instance of the API service
const apiService = new ApiService()

export default class ContentManagementService {
	async list(queryParams = {}) {
		return await apiService.get(`content-management`, {}, queryParams)
	}

	async get(idOrSlug = null) {
		return await apiService.get(`content-management/${idOrSlug}`)
	}

	async put(id, formData = {}) {
		return await apiService.put(`content-management/${id}`, {body: formData}, true)
	}

	formData(inputValues) {
		const formData = new FormData()

		Object.keys(inputValues).forEach(fieldKey => {
			if (typeof inputValues[fieldKey] === 'object') {
				const scope = fieldKey
				Object.keys(inputValues[scope]).forEach(scopeFieldKey => {
					if (Array.isArray(inputValues[scope][scopeFieldKey])) {
						inputValues[scope][scopeFieldKey].forEach((groupValues, groupKey) => {
							if (typeof inputValues[scope][scopeFieldKey][groupKey] === 'object') { // content
								Object.keys(inputValues[scope][scopeFieldKey][groupKey]).forEach(groupFieldKey => {
									if (inputValues[scope][scopeFieldKey][groupKey][groupFieldKey] !== null) {
										formData.append(`${scope}[${scopeFieldKey}][${groupKey}][${groupFieldKey}]`, inputValues[scope][scopeFieldKey][groupKey][groupFieldKey])
									}
									else {
										formData.append(`${scope}[${scopeFieldKey}][${groupKey}][${groupFieldKey}]`, '')
									}
								})
							}
							else {
								if (inputValues[scope][scopeFieldKey][groupKey] !== null) {
									formData.append(`${scope}[${scopeFieldKey}][${groupKey}]`, inputValues[scope][scopeFieldKey][groupKey])
								}
								else {
									formData.append(`${scope}[${scopeFieldKey}][${groupKey}]`, '')
								}
							}
						})
					}
					else {
						if (inputValues[scope][scopeFieldKey] !== null) {
							formData.append(`${scope}[${scopeFieldKey}]`, inputValues[scope][scopeFieldKey])
						}
						else {
							formData.append(`${scope}[${scopeFieldKey}]`, '')
						}
					}
				})
			}
			else {
				if (inputValues[fieldKey] !== null) {
					formData.append(fieldKey, inputValues[fieldKey])
				}
				else {
					formData.append(fieldKey, '')
				}
			}
		})

		return formData
	}
}
