
// Libraries
import * as React from 'react'

// Services
import AppService from '../../../services/app'
import ContentManagementService from '../../../services/contentManagement'

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminContentManagementTableHeader from '../../../components/admin/contentManagement/adminContentManagementTableHeader'
import AdminContentManagementTable from '../../../components/admin/contentManagement/adminContentManagementTable'
import AdminContentManagementEdit from '../../../components/admin/contentManagement/adminContentManagementEdit'
import AdminContentManagementConfirmBuild from '../../../components/admin/contentManagement/adminContentManagementConfirmBuild'
import {faPenSquare} from '@fortawesome/pro-solid-svg-icons'

// Context
import NotificationContext from '../../../contexts/notification'

class AdminContentManagementIndexPage extends React.Component {
	state = {
		items: [],
		emptyMessage: 'Retrieving pages ...',
		selectedItem: {},
		modalEditShow: false,
		modalConfirmBuildShow: false,
	}

	static contextType = NotificationContext

	render() {
		const {activeFilters, items, selectedItem, emptyMessage, modalEditShow, modalConfirmBuildShow} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-table page--admin-table-content-management nav-blue-half">
				<Seo title="Site Content Management" />
				<div className="admin-table__wrap">
					<AdminContentManagementTableHeader
						titleText="Site Content Management"
						titleIcon={faPenSquare}
						handleConfirmBuild={this.handleConfirmBuild} />

					<AdminContentManagementTable
						activeFilters={activeFilters}
						items={items}
						emptyMessage={emptyMessage}
						handleOpenEdit={this.handleOpenEdit} />

					{modalEditShow && <AdminContentManagementEdit
						item={selectedItem}
						handleClose={() => this.setState({
							modalEditShow: false
						})}
						handleEditComplete={this.handleEditComplete} />}

					{modalConfirmBuildShow && <AdminContentManagementConfirmBuild
						handleClose={() => this.setState({modalConfirmBuildShow: false})}
						handleConfirm={this.handleProcessBuild} />}
				</div>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		const {addNotification} = this.context

		Promise.all([
			this.getItems({}, false)
				.catch(() => addNotification('There was an error fetching the pages.', 'error'))
		]).then(() => {
			this.setState({
				activeFilters: {},
				emptyMessage: "No pages found, try adjusting your filters"
			})
		})
	}

	getItems = (queryParams) => {
		const contentManagementService = new ContentManagementService()
		return contentManagementService.list(queryParams).then( (response) => {
			this.setState({
				items: response.data
			})
		}).catch(err => console.error(err))
	}

	handleOpenEdit = (itemId) => {
		const contentManagementService = new ContentManagementService()
		return contentManagementService.get(itemId)
			.then( (response) => {
				this.updateStateSelectedItem(response.data)
				this.setState({
					modalEditShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleEditComplete = (item) => {
		this.updateStateSelectedItem(item)
		this.updateStateItems(item)
		this.setState({
			modalEditShow: false
		})
	}

	handleConfirmBuild = () => {
		this.setState({
			modalConfirmBuildShow: true
		})
	}

	handleProcessBuild = () => {
		const {addNotification} = this.context

		const appService = new AppService()
		return appService.triggerBuild()
			.then( (response) => {
				if (response.data?.success) {
					addNotification('Build triggered', 'success')

					this.setState({
						modalConfirmBuildShow: false
					})
				} else {
					addNotification('Unable to trigger a build', 'error')
				}
			})
			.catch(err => console.error(err))
	}

	updateStateSelectedItem = (item) => {
		this.setState(prevState => ({
			selectedItem: {...prevState.selectedItem, ...item}
		}))
	}

	updateStateItems = (item, remove = false) => {
		const {items} = this.state

		// update items state
		items.forEach((existingItem, key) => {
			if (existingItem.id === item.id) {
				if (remove) {
					items.splice(key, 1)
				}
				else {
					items[key] = {...existingItem, ...item}
				}
			}
		})

		this.setState({items})
	}
}

export default AdminContentManagementIndexPage
