
// Libraries
import * as React from 'react'

// Services
import ContentManagementService from '../../../services/contentManagement'

// Context
import NotificationContext from '../../../contexts/notification'

// Components
import Modal from '../../modal'
import AdminViewRow from '../adminViewRow'
import InputFactory from '../../inputFactory'
import Button from '../../button'
import InputGroupCategories from '../../inputGroupCategories'
import InputGroupFaqs from '../../inputGroupFaqs'
import InputGroupTeamProfiles from '../../inputGroupTeamProfiles'
import InputGroupTestimonialQuotes from '../../inputGroupTestimonialQuotes'
import InputGroupVenuePartners from '../../inputGroupVenuePartners'

export default class AdminContentManagementEdit extends React.Component {
	state = {
		initialValues: {},
		inputValues: {},
		inputErrors: {},
		isSubmitting: false,
	}

	static contextType = NotificationContext

	render() {
		const {item, handleClose} = this.props
		const {initialValues, inputValues, inputErrors, isSubmitting} = this.state

		return <Modal handleClose={handleClose} modalRight adminForm>
			<div className="admin-form admin-form--content-management">
				<div className="admin-form__header">
					<div className="column">
						<h3>Edit: {item.name}</h3>
					</div>
					<div className="column">
					</div>
				</div>
				<div className="admin-form__form">
					<form onSubmit={this.handleSubmit}>
						{['home'].includes(item.slug) &&
							<>
								<div className="admin-form__field-group">
									<h2>Hero</h2>
									<AdminViewRow
										label="Title"
										value={<InputFactory
											type="text"
											name="hero_title"
											scope="content"
											value={inputValues?.content?.hero_title}
											error={inputErrors?.content?.hero_title}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Subtitle"
										value={<InputFactory
											type="textarea"
											name="hero_subtitle"
											scope="content"
											value={inputValues?.content?.hero_subtitle}
											error={inputErrors?.content?.hero_subtitle}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Button 1: Text"
										value={<InputFactory
											type="text"
											name="hero_button1_text"
											scope="content"
											value={inputValues?.content?.hero_button1_text}
											error={inputErrors?.content?.hero_button1_text}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Button 1: URL"
										value={<InputFactory
											type="text"
											name="hero_button1_url"
											scope="content"
											value={inputValues?.content?.hero_button1_url}
											error={inputErrors?.content?.hero_button1_url}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Button 2: Text"
										value={<InputFactory
											type="text"
											name="hero_button2_text"
											scope="content"
											value={inputValues?.content?.hero_button2_text}
											error={inputErrors?.content?.hero_button2_text}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Button 2: URL"
										value={<InputFactory
											type="text"
											name="hero_button2_url"
											scope="content"
											value={inputValues?.content?.hero_button2_url}
											error={inputErrors?.content?.hero_button2_url}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Button 3: Text"
										value={<InputFactory
											type="text"
											name="hero_button3_text"
											scope="content"
											value={inputValues?.content?.hero_button3_text}
											error={inputErrors?.content?.hero_button3_text}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Button 3: URL"
										value={<InputFactory
											type="text"
											name="hero_button3_url"
											scope="content"
											value={inputValues?.content?.hero_button3_url}
											error={inputErrors?.content?.hero_button3_url}
											onChange={this.handleInputChange}
										/>}
									/>
								</div>

								<div className="admin-form__field-group">
									<h2>How it Works</h2>
									<AdminViewRow
										label="Title"
										value={<InputFactory
											type="text"
											name="how_it_works_title"
											scope="content"
											value={inputValues?.content?.how_it_works_title}
											error={inputErrors?.content?.how_it_works_title}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Step 1"
										value={<InputFactory
											type="text"
											name="how_it_works_step1"
											scope="content"
											value={inputValues?.content?.how_it_works_step1}
											error={inputErrors?.content?.how_it_works_step1}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Step 2"
										value={<InputFactory
											type="text"
											name="how_it_works_step2"
											scope="content"
											value={inputValues?.content?.how_it_works_step2}
											error={inputErrors?.content?.how_it_works_step2}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Step 3"
										value={<InputFactory
											type="text"
											name="how_it_works_step3"
											scope="content"
											value={inputValues?.content?.how_it_works_step3}
											error={inputErrors?.content?.how_it_works_step3}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Outro: Small"
										value={<InputFactory
											type="textarea"
											name="how_it_works_outro_small"
											scope="content"
											value={inputValues?.content?.how_it_works_outro_small}
											error={inputErrors?.content?.how_it_works_outro_small}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Outro: Large"
										value={<InputFactory
											type="tinymce"
											name="how_it_works_outro_large"
											scope="content"
											initialValue={initialValues?.content?.how_it_works_outro_large}
											value={inputValues?.content?.how_it_works_outro_large}
											error={inputErrors?.content?.how_it_works_outro_large}
											onChange={this.handleInputChange}
											config="page"
											removeScriptHost={true}
										/>}
									/>
								</div>

								<div className="admin-form__field-group">
									<h2>Featured Events</h2>
									<AdminViewRow
										label="Title"
										value={<InputFactory
											type="text"
											name="featured_events_title"
											scope="content"
											value={inputValues?.content?.featured_events_title}
											error={inputErrors?.content?.featured_events_title}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Introduction"
										value={<InputFactory
											type="textarea"
											name="featured_events_intro"
											scope="content"
											value={inputValues?.content?.featured_events_intro}
											error={inputErrors?.content?.featured_events_intro}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Button: Text"
										value={<InputFactory
											type="text"
											name="featured_events_button_text"
											scope="content"
											value={inputValues?.content?.featured_events_button_text}
											error={inputErrors?.content?.featured_events_button_text}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Button: URL"
										value={<InputFactory
											type="text"
											name="featured_events_button_url"
											scope="content"
											value={inputValues?.content?.featured_events_button_url}
											error={inputErrors?.content?.featured_events_button_url}
											onChange={this.handleInputChange}
										/>}
									/>
								</div>
							</>
						}

						{['about', 'cookie-policy', 'terms', 'privacy-policy', 'contact'].includes(item.slug) &&
							<>
								<div className="admin-form__field-group">
									<h2>Main</h2>
									<AdminViewRow
										label="Title"
										value={<InputFactory
											type="text"
											name="title"
											scope="content"
											value={inputValues?.content?.title}
											error={inputErrors?.content?.title}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Content"
										value={<InputFactory
											type="tinymce"
											name="content"
											scope="content"
											initialValue={initialValues?.content?.content}
											value={inputValues?.content?.content}
											error={inputErrors?.content?.content}
											onChange={this.handleInputChange}
											config="page"
											removeScriptHost={true}
										/>}
									/>
								</div>
							</>
						}

						{['contact'].includes(item.slug) &&
							<>
								<div className="admin-form__field-group">
									<h2>Social Links</h2>
									<AdminViewRow
										label="Twitter"
										value={<InputFactory
											type="text"
											name="twitter"
											scope="content"
											value={inputValues?.content?.twitter}
											error={inputErrors?.content?.twitter}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Facebook"
										value={<InputFactory
											type="text"
											name="facebook"
											scope="content"
											value={inputValues?.content?.facebook}
											error={inputErrors?.content?.facebook}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Instagram"
										value={<InputFactory
											type="text"
											name="instagram"
											scope="content"
											value={inputValues?.content?.instagram}
											error={inputErrors?.content?.instagram}
											onChange={this.handleInputChange}
										/>}
									/>
								</div>
							</>
						}

						{['our-partners'].includes(item.slug) &&
							<>
								<div className="admin-form__field-group">
									<h2>Header</h2>
									<AdminViewRow
										label="Title"
										value={<InputFactory
											type="text"
											name="header_title"
											scope="content"
											value={inputValues?.content?.header_title}
											error={inputErrors?.content?.header_title}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Introduction"
										value={<InputFactory
											type="textarea"
											name="header_introduction"
											scope="content"
											value={inputValues?.content?.header_introduction}
											error={inputErrors?.content?.header_introduction}
											onChange={this.handleInputChange}
										/>}
									/>
								</div>

								<div className="admin-form__field-group">
									<h2>Partner 1</h2>
									<AdminViewRow
										label="Name"
										value={<InputFactory
											type="text"
											name="partner2_name"
											scope="content"
											value={inputValues?.content?.partner2_name}
											error={inputErrors?.content?.partner2_name}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Overview"
										value={<InputFactory
											type="tinymce"
											name="partner2_overview"
											scope="content"
											initialValue={initialValues?.content?.partner2_overview}
											value={inputValues?.content?.partner2_overview}
											error={inputErrors?.content?.partner2_overview}
											onChange={this.handleInputChange}
											config="page"
											removeScriptHost={true}
										/>}
									/>
									<AdminViewRow
										label="Logo"
										value={<InputFactory
											type="file"
											name="image_partner2_logo"
											scope="content"
											value={inputValues?.content?.image_partner2_logo}
											initialFilePreview={initialValues?.content?.image_partner2_logo || null}
											error={inputErrors?.content?.image_partner2_logo}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Logo Mobile Header"
										value={<InputFactory
											type="file"
											name="image_partner2_logo_mobile"
											scope="content"
											value={inputValues?.content?.image_partner2_logo_mobile}
											initialFilePreview={initialValues?.content?.image_partner2_logo_mobile || null}
											error={inputErrors?.content?.image_partner2_logo_mobile}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Content Image"
										value={<InputFactory
											type="file"
											name="image_partner2_logo_mobile_header"
											scope="content"
											value={inputValues?.content?.image_partner2_logo_mobile_header}
											initialFilePreview={initialValues?.content?.image_partner2_logo_mobile_header || null}
											error={inputErrors?.content?.image_partner2_logo_mobile_header}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Website URL"
										value={<InputFactory
											type="text"
											name="partner2_website"
											scope="content"
											value={inputValues?.content?.partner2_website}
											error={inputErrors?.content?.partner2_website}
											onChange={this.handleInputChange}
										/>}
									/>
								</div>

								<div className="admin-form__field-group">
									<h2>Partner 2</h2>
									<AdminViewRow
										label="Name"
										value={<InputFactory
											type="text"
											name="partner3_name"
											scope="content"
											value={inputValues?.content?.partner3_name}
											error={inputErrors?.content?.partner3_name}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Overview"
										value={<InputFactory
											type="tinymce"
											name="partner3_overview"
											scope="content"
											initialValue={initialValues?.content?.partner3_overview}
											value={inputValues?.content?.partner3_overview}
											error={inputErrors?.content?.partner3_overview}
											onChange={this.handleInputChange}
											config="page"
											removeScriptHost={true}
										/>}
									/>
									<AdminViewRow
										label="Logo"
										value={<InputFactory
											type="file"
											name="image_partner3_logo"
											scope="content"
											value={inputValues?.content?.image_partner3_logo}
											initialFilePreview={initialValues?.content?.image_partner3_logo || null}
											error={inputErrors?.content?.image_partner3_logo}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Logo Mobile Header"
										value={<InputFactory
											type="file"
											name="image_partner3_logo_mobile"
											scope="content"
											value={inputValues?.content?.image_partner3_logo_mobile}
											initialFilePreview={initialValues?.content?.image_partner3_logo_mobile || null}
											error={inputErrors?.content?.image_partner3_logo_mobile}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Content Image"
										value={<InputFactory
											type="file"
											name="image_partner3_logo_mobile_header"
											scope="content"
											value={inputValues?.content?.image_partner3_logo_mobile_header}
											initialFilePreview={initialValues?.content?.image_partner3_logo_mobile_header || null}
											error={inputErrors?.content?.image_partner3_logo_mobile_header}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Website URL"
										value={<InputFactory
											type="text"
											name="partner3_website"
											scope="content"
											value={inputValues?.content?.partner3_website}
											error={inputErrors?.content?.partner3_website}
											onChange={this.handleInputChange}
										/>}
									/>
								</div>
							</>
						}

						{['donate'].includes(item.slug) &&
							<>
								<div className="admin-form__field-group">
									<h2>Header</h2>
									<AdminViewRow
										label="Introduction"
										value={<InputFactory
											type="textarea"
											name="header_introduction"
											scope="content"
											value={inputValues?.content?.header_introduction}
											error={inputErrors?.content?.header_introduction}
											onChange={this.handleInputChange}
										/>}
									/>
								</div>

								<div className="admin-form__field-group">
									<h2>Explainer: Why</h2>
									<AdminViewRow
										label="Title"
										value={<InputFactory
											type="text"
											name="why_title"
											scope="content"
											value={inputValues?.content?.why_title}
											error={inputErrors?.content?.why_title}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Content"
										value={<InputFactory
											type="tinymce"
											name="why_content"
											scope="content"
											initialValue={initialValues?.content?.why_content}
											value={inputValues?.content?.why_content}
											error={inputErrors?.content?.why_content}
											onChange={this.handleInputChange}
											config="page"
											removeScriptHost={true}
										/>}
									/>
								</div>

								<div className="admin-form__field-group">
									<h2>Explainer: How</h2>
									<AdminViewRow
										label="Title"
										value={<InputFactory
											type="text"
											name="how_title"
											scope="content"
											value={inputValues?.content?.how_title}
											error={inputErrors?.content?.how_title}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Step 1: Title"
										value={<InputFactory
											type="text"
											name="how_step1_title"
											scope="content"
											value={inputValues?.content?.how_step1_title}
											error={inputErrors?.content?.how_step1_title}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Step 1: Content"
										value={<InputFactory
											type="tinymce"
											name="how_step1_content"
											scope="content"
											initialValue={initialValues?.content?.how_step1_content}
											value={inputValues?.content?.how_step1_content}
											error={inputErrors?.content?.how_step1_content}
											onChange={this.handleInputChange}
											config="page"
											removeScriptHost={true}
										/>}
									/>
									<AdminViewRow
										label="Step 2: Title"
										value={<InputFactory
											type="text"
											name="how_step2_title"
											scope="content"
											value={inputValues?.content?.how_step2_title}
											error={inputErrors?.content?.how_step2_title}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Step 2: Content"
										value={<InputFactory
											type="tinymce"
											name="how_step2_content"
											scope="content"
											initialValue={initialValues?.content?.how_step2_content}
											value={inputValues?.content?.how_step2_content}
											error={inputErrors?.content?.how_step2_content}
											onChange={this.handleInputChange}
											config="page"
											removeScriptHost={true}
										/>}
									/>
									<AdminViewRow
										label="Step 3: Title"
										value={<InputFactory
											type="text"
											name="how_step3_title"
											scope="content"
											value={inputValues?.content?.how_step3_title}
											error={inputErrors?.content?.how_step3_title}
											onChange={this.handleInputChange}
										/>}
									/>
									<AdminViewRow
										label="Step 3: Content"
										value={<InputFactory
											type="tinymce"
											name="how_step3_content"
											scope="content"
											initialValue={initialValues?.content?.how_step3_content}
											value={inputValues?.content?.how_step3_content}
											error={inputErrors?.content?.how_step3_content}
											onChange={this.handleInputChange}
											config="page"
											removeScriptHost={true}
										/>}
									/>
								</div>
							</>
						}

						{['faqs'].includes(item.slug) &&
							<>
								<InputGroupCategories
									values={inputValues?.content?.categories}
									inputChange={this.handleInputChange}
									inputAdd={this.handleInputGroupAdd}
									inputRemove={this.handleInputGroupRemove}
								/>
								<InputGroupFaqs
									initialValues={initialValues?.content?.faqs}
									values={inputValues?.content?.faqs}
									inputChange={this.handleInputChange}
									inputAdd={this.handleInputGroupAdd}
									inputRemove={this.handleInputGroupRemove}
								/>
							</>
						}

						{['team-profiles'].includes(item.slug) &&
							<>
								<InputGroupTeamProfiles
									values={inputValues?.content?.profiles}
									inputChange={this.handleInputChange}
									inputAdd={this.handleInputGroupAdd}
									inputRemove={this.handleInputGroupRemove}
								/>
							</>
						}

						{['testimonial-quotes'].includes(item.slug) &&
							<>
								<InputGroupTestimonialQuotes
									values={inputValues?.content?.quotes}
									inputChange={this.handleInputChange}
									inputAdd={this.handleInputGroupAdd}
									inputRemove={this.handleInputGroupRemove}
								/>
							</>
						}

						{['venue-partners'].includes(item.slug) &&
							<>
								<InputGroupVenuePartners
									values={inputValues?.content?.venues}
									inputChange={this.handleInputChange}
									inputAdd={this.handleInputGroupAdd}
									inputRemove={this.handleInputGroupRemove}
								/>
							</>
						}

						<div className="admin-form__submit-wrap">
							<Button type="button" onClick={handleClose} colorEndeavour hollow>Discard Changes</Button>
							<Button type="submit" isLoading={isSubmitting} colorEndeavour>Save Changes</Button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	}

	componentDidMount() {
		const {item} = this.props
		const {initialValues, inputValues} = this.state

		initialValues.content = item.content ? JSON.parse(JSON.stringify(item.content)) : {}

		inputValues.content = item.content ?? {}

		this.setState({ inputValues, initialValues })
	}

	handleInputChange = (value, name, scope) => {
		const {inputValues} = this.state

		let nameSplit = null
		if (name.includes('[')) {
			nameSplit = name.replaceAll(']', '').split('[')
		}

		if (value) {
			if (nameSplit) {
				if (nameSplit.length === 4) {
					inputValues[nameSplit[0]][nameSplit[1]][nameSplit[2]][nameSplit[3]] = value
				}
				else if (nameSplit.length === 3) {
					inputValues[nameSplit[0]][nameSplit[1]][nameSplit[2]] = value
				}
				else if (nameSplit.length === 2) {
					inputValues[nameSplit[0]][nameSplit[1]] = value
				}
			}
			else if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			if (nameSplit) {
				if (nameSplit.length === 4) {
					inputValues[nameSplit[0]][nameSplit[1]][nameSplit[2]][nameSplit[3]] = ''
				}
				else if (nameSplit.length === 3) {
					inputValues[nameSplit[0]][nameSplit[1]][nameSplit[2]] = ''
				}
				else if (nameSplit.length === 2) {
					inputValues[nameSplit[0]][nameSplit[1]] = ''
				}
			}
			else if (scope) {
				inputValues[scope][name] = ''
			}
			else {
				inputValues[name] = ''
			}
		}

		this.setState({ inputValues })
	}

	handleInputGroupAdd = (type) => {
		const {inputValues} = this.state

		let valuesObject = {}
		if (type === 'quotes') {
			valuesObject = {
				name: '',
				event: '',
				quote: '',
				link: ''
			}
		}
		else if (type === 'venues') {
			valuesObject = {
				name: '',
				image_logo: ''
			}
		}
		else if (type === 'profiles') {
			valuesObject = {
				name: '',
				position: '',
				image_photo: '',
				contacts: '',
				favourite_artist: '',
				best_gig: '',
				profile_text: ''
			}
		}
		else if (type === 'categories') {
			valuesObject = {
				name: ''
			}
		}
		else if (type === 'faqs') {
			valuesObject = {
				question: '',
				answer: ''
			}
		}
		inputValues.content[type].push(valuesObject)

		this.setState({ inputValues })
	}

	handleInputGroupRemove = (type, key) => {
		// Get current form data from state
		const {inputValues} = this.state

		// remove recipient value from state
		inputValues.content[type].splice(key, 1)

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleSubmit = async (submitEvent = null) => {
		submitEvent.preventDefault()
		this.setState({
			isSubmitting: true
		})

		const {item, handleEditComplete} = this.props
		const {inputValues} = this.state
		const contentManagementService = new ContentManagementService()
		const {addNotification} = this.context

		const formData = contentManagementService.formData(inputValues)
		await contentManagementService.put(item.id, formData)
			.then(async (response) => {
				if (response.success) {
					addNotification('Page Updated', 'success')
					handleEditComplete(response.data)
				}
				else {
					const inputErrors = response.errors
					this.setState({
						inputErrors: inputErrors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}
